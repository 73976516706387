import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import ToTop from "../../atoms/ToTop";

import PostMarkdown from "./Thunderstorms.md";

class Thunderstorms extends Component {
  constructor(props) {
    super(props);

    this.state = { markdown: "" };
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(PostMarkdown)
      .then((res) => res.text())
      .then((text) => this.setState({ markdown: text }));
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Thunderstorms for Sleep</title>
          <meta name="og:title" content="Thunderstorms for Sleep" />
          <meta
            name="description"
            content="Turns out I really like falling asleep to the sound of thunderstorms."
          />
          <meta
            name="og:description"
            content="Turns out I really like falling asleep to the sound of thunderstorms."
          />
          <meta
            name="twitter:image"
            content="https://projects.jcpretorius.earth/IMG/projects.png"
          />
        </Helmet>
        <ReactMarkdown source={this.state.markdown} escapeHtml={false} />

        <h2>1 Hour</h2>
        <p>
          <i className="text-sm">About 55mb. Your browser should cache it.</i>
        </p>
        <audio
          className="w-full"
          src="https://cdn.webproject.za.net/media/thunderstorms_1hr_jcp.mp3"
          controls
        />

        <ToTop></ToTop>
      </div>
    );
  }
}

export default Thunderstorms;
