import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store.js";

import "./css/hamburgers.css";
import "./App.css";

import Header from "./Components/organisms/Header/Header.js";
import Navigation from "./Components/organisms/Navigation/Navigation.js";
import Resources from "./Components/organisms/Resources/Resources.js";

import Home from "./Components/pages/Home/Home.js";
import NotFound from "./Components/pages/NotFound/NotFound.js";

// Active
import JacobEarth from "./Components/pages/JacobEarth/JacobEarth.js";
import BlueskyLinkStream from "./Components/pages/BlueskyLinkStream/BlueskyLinkStream.js";
import Elitemap from "./Components/pages/Elitemap/Elitemap.js";
import ohonesix from "./Components/pages/ohonesix/ohonesix.js";

// Stable
import RichlistXyz from "./Components/pages/RichlistXyz/RichlistXyz.js";
import DiscordNFTBot from "./Components/pages/DiscordNFTBot/DiscordNFTBot.js";
import VanillaTimeline from "./Components/pages/VanillaTimeline/VanillaTimeline.js";
import JustStarted from "./Components/pages/JustStarted/JustStarted.js";
import InfiniteScrollBlocker from "./Components/pages/InfiniteScrollBlocker/InfiniteScrollBlocker.js";
import CrazymanPc from "./Components/pages/CrazymanPc/CrazymanPc.js";
import RelativelyProductive from "./Components/pages/RelativelyProductive/RelativelyProductive.js";
import SaturdayEarth from "./Components/pages/SaturdayEarth/SaturdayEarth.js";
import PostureChecker from "./Components/pages/PostureChecker/PostureChecker.js";
import StartPage from "./Components/pages/StartPage/StartPage.js";
import Thunderstorms from "./Components/pages/Thunderstorms/Thunderstorms.js";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="">
          <Header />

          <div className="flex flex-wrap">
            <Navigation />
            <div className="main-body pt-4 md:pt-6 py-6 px-10 md:px-6 w-full md:w-3/5 md:max-w-2xl">
              <Switch>
                <Route exact path="/" component={Home} />

                {/* Active projects */}
                <Route exact path="/jacob-earth" component={JacobEarth} />
                <Route
                  exact
                  path="/bluesky-link-stream"
                  component={BlueskyLinkStream}
                />
                <Route exact path="/elitemap" component={Elitemap} />
                <Route exact path="/ohonesix" component={ohonesix} />

                {/* Stable projects */}
                <Route exact path="/richlist-xyz" component={RichlistXyz} />
                <Route
                  exact
                  path="/discord-nft-bot"
                  component={DiscordNFTBot}
                />
                <Route
                  exact
                  path="/vanilla-timeline"
                  component={VanillaTimeline}
                />
                <Route exact path="/juststarted" component={JustStarted} />
                <Route exact path="/crazyman-pc" component={CrazymanPc} />
                <Route
                  exact
                  path="/infinite-scroll-blocker"
                  component={InfiniteScrollBlocker}
                />
                <Route exact path="/saturday-earth" component={SaturdayEarth} />
                <Route
                  exact
                  path="/relatively-productive"
                  component={RelativelyProductive}
                />
                <Route
                  exact
                  path="/posture-checker"
                  component={PostureChecker}
                />
                <Route exact path="/start-page" component={StartPage} />
                <Route exact path="/thunderstorms" component={Thunderstorms} />

                <Route path="*" component={NotFound} />
              </Switch>
            </div>
            <Resources />
          </div>

          {/* <Footer></Footer> */}
        </div>
      </Router>
    </Provider>
  );
}

export default App;
