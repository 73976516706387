import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { setResources } from "../../../store/actions";
import ToTop from "../../atoms/ToTop";

import PostMarkdown from "./JustStarted.md";

class JustStarted extends Component {
  constructor(props) {
    super(props);

    // The body markdown goes in here
    this.state = { markdown: "" };

    // Set details for the resources panel
    this.props.setResources({
      download: "https://github.com/jacobpretorius/JustStarted/releases",
      github: "https://github.com/jacobpretorius/JustStarted",
      privacy:
        "https://github.com/jacobpretorius/JustStarted/blob/master/privacy-policy.md",
      website: "",
      twitter: "",
      tech: "Node, Electronjs, Javascript, CSS",
    });
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(PostMarkdown)
      .then((res) => res.text())
      .then((text) => this.setState({ markdown: text }));
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>JustStarted for Toggl</title>
          <meta name="og:title" content="JustStarted for Toggl" />
          <meta
            name="description"
            content="Easily start and stop Toggl timers."
          />
          <meta
            name="og:description"
            content="Easily start and stop Toggl timers."
          />
          <meta
            name="twitter:image"
            content="https://projects.jcpretorius.earth/IMG/post/JustStarted.png"
          />
        </Helmet>
        <ReactMarkdown source={this.state.markdown} escapeHtml={false} />

        <ToTop></ToTop>
      </div>
    );
  }
}

export default connect(null, { setResources })(JustStarted);
