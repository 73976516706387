import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { setResources } from "../../../store/actions";
import ToTop from "../../atoms/ToTop";

import PostMarkdown from "./InfiniteScrollBlocker.md";

class InfiniteScrollBlocker extends Component {
  constructor(props) {
    super(props);

    // The body markdown goes in here
    this.state = { markdown: "" };

    // Set details for the resources panel
    this.props.setResources({
      download:
        "https://chrome.google.com/webstore/detail/infinite-scroll-blocker/pbpfkabjjkbnenfmpcnkpfiabplihndp",
      github: "https://github.com/jacobpretorius/Infinite-Scroll-Blocker",
      privacy: "",
      website: "",
      twitter: "",
      tech: "Javascript, Chrome-extension-api",
    });
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(PostMarkdown)
      .then((res) => res.text())
      .then((text) => this.setState({ markdown: text }));
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Infinite Scroll Blocker</title>
          <meta name="og:title" content="Infinite Scroll Blocker" />
          <meta
            name="description"
            content="Stop unintentional scrolling on all websites."
          />
          <meta
            name="og:description"
            content="Stop unintentional scrolling on all websites."
          />
          <meta
            name="twitter:image"
            content="https://projects.jcpretorius.earth/IMG/post/InfiniteScrollBlocker.png"
          />
        </Helmet>
        <ReactMarkdown source={this.state.markdown} escapeHtml={false} />

        <ToTop></ToTop>
      </div>
    );
  }
}

export default connect(null, { setResources })(InfiniteScrollBlocker);
