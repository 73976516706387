import React, { Component } from "react";
import Link from "../../atoms/Link";

import { connect } from "react-redux";
import { toggleNavMenu } from "../../../store/actions";

class Navigation extends Component {
  render() {
    return (
      <div
        className={`w-full md:w-1/5 h-full pb-0 pt-8 px-10 md:pl-6 hidden md:block md:pr-2 md:sticky top-0 ${
          this.props.navMenuOpen ? "show-nav" : ""
        }`}>
        <nav>
          <h4 className="text-sm text-grey">Active</h4>
          <ul>
            <li>
              <Link>Jacob Earth</Link>
            </li>
            <li>
              <Link>Bluesky Link Stream</Link>
            </li>
            <li>
              <Link>Elitemap</Link>
            </li>
            <li>
              <Link>ohonesix</Link>
            </li>
          </ul>
          <h4 className="text-sm text-grey">Stable</h4>
          <ul>
            <li>
              <Link>Richlist.xyz</Link>
            </li>
            <li>
              <Link>Discord NFT Bot</Link>
            </li>
            <li>
              <Link>Start Page</Link>
            </li>
          </ul>
          <h4 className="text-sm text-grey">Sunset</h4>
          <ul>
            <li>
              <Link>Vanilla Timeline</Link>
            </li>
            <li>
              <Link>JustStarted</Link>
            </li>
            <li>
              <Link>Infinite Scroll Blocker</Link>
            </li>
            <li>
              <Link>Relatively Productive</Link>
            </li>
            <li>
              <Link>Crazyman PC</Link>
            </li>
            <li>
              <Link>Posture Checker</Link>
            </li>
            <li>
              <Link>Saturday Earth</Link>
            </li>
            <li>
              <Link>Thunderstorms</Link>
            </li>
          </ul>
          <h4 className="text-sm text-grey md:hidden">General</h4>
          <ul className="md:hidden">
            <li>
              <Link link="https://jacob.earth/blog">Blog</Link>
            </li>
            <li>
              <Link link="https://x.com/jacob_pretorius">Twitter</Link>
            </li>
          </ul>
        </nav>
        <div className="border-solid border-b-2 border-gray-300 md:border-none"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { navMenuOpen: state.nav.navMenuOpen };
};

export default connect(mapStateToProps, { toggleNavMenu })(Navigation);
